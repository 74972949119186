import { IApiSellerCategoryOfList } from "@/types/interfaces/categories";
import { crudFetcherSeller } from "services/crud";
import apiSeller from "./constants/apiSeller";
import { IApiSellerProperty } from "@/types/interfaces/properties";
import { IApiSellerProfile } from "@/types/interfaces/account";
import { downloadFile } from "@/utils/file";
import { FileContentType } from "@/types/interfaces/files";
import { uploadChunk } from "@/utils/requests";

const getStore = () => {
  return crudFetcherSeller.post({
    url: apiSeller.store(),
    isSensitiveContentLanguage: true,
  });
};

const getCategory = (slug: any) => {
  return crudFetcherSeller.get({
    url: apiSeller.category(slug),
    isSensitiveContentLanguage: true,
  });
};

const getCategoryById = (categoryId: any) => {
  return crudFetcherSeller.get({
    url: apiSeller.categoryById(categoryId),
    isSensitiveContentLanguage: true,
  });
};

const getCategoriesByIds = (ids: (number | string)[] = []): Promise<IApiSellerCategoryOfList[]> => {
  return crudFetcherSeller.get({
    url: `${apiSeller.categories()}?ids=${ids.join(",")}`,
    isSensitiveContentLanguage: true,
  });
};

const getCategoriesByParent = (parent: number | string | null): Promise<IApiSellerCategoryOfList[]> => {
  return crudFetcherSeller.get({
    url: `${parent}` === "null" ? `${apiSeller.categories()}?level=0` : `${apiSeller.categories()}?parent=${parent}`,
    isSensitiveContentLanguage: true,
  });
};

const updateStore = (requestData: any, isPatchUpdate: boolean = false, storeId: string = "") => {
  const requests = isPatchUpdate ? crudFetcherSeller.patch : crudFetcherSeller.put;
  const url = isPatchUpdate ? apiSeller.storeUpdate(storeId) : apiSeller.store();

  return requests({
    url,
    requestData,
    isSensitiveContentLanguage: true,
  });
};

const switchStoreActivation = (requestData: any, storeId: string) => {
  return crudFetcherSeller.post({
    url: apiSeller.switchActivation(storeId),
    requestData,
    isSensitiveContentLanguage: true,
  });
};

const getProductsIds = (filtersStr?: string): Promise<{ id: number }[]> => {
  return crudFetcherSeller.get({
    url: `${apiSeller.productsIds()}${filtersStr ? `?${filtersStr}` : ""}`,
  });
};

const addProduct = (requestData: any) => {
  return crudFetcherSeller.post({
    url: apiSeller.productCreate(),
    requestData,
    isSensitiveContentLanguage: true,
  });
};

const uniquenessCheckSKU = (sku: string): Promise<true | string> => {
  return crudFetcherSeller
    .post({
      url: apiSeller.uniquenessCheckSKU(),
      requestData: { sku },
      isSensitiveContentLanguage: true,
    })
    .then(() => true)
    .catch((err) => {
      if (err.http_status === 400) {
        return Array.isArray(err.sku) ? err.sku.join(`\n`) : err.sku;
      }

      return JSON.stringify(err);
    });
};

const updateProduct = (productId: any, requestData: any) => {
  return crudFetcherSeller.patch({
    url: apiSeller.productUpdate(productId),
    requestData,
    isSensitiveContentLanguage: true,
  });
};

interface BulkUpdateProductsData {
  product_ids: number[];
  categories_ids?: number[];
  is_active?: boolean;
}

const bulkUpdateProducts = (requestData: BulkUpdateProductsData) => {
  return crudFetcherSeller.patch({
    url: apiSeller.bulkEditProducts(),
    requestData,
  });
};

const deleteProduct = (productId: any) => {
  return crudFetcherSeller.delete({
    url: apiSeller.productUpdate(productId),
  });
};

// Properties
const getProperties = (locale: string) => {
  return crudFetcherSeller.get({
    url: apiSeller.properties(locale),
  });
};

const getSystemProperties = (locale: string) => {
  return crudFetcherSeller.get({
    url: apiSeller.systemProperties(locale),
  });
};

const getCustomProperties = (locale: string) => {
  return crudFetcherSeller.get({
    url: apiSeller.customProperties(locale),
  });
};

const getProductProperties = (locale: string, productId: any) => {
  return crudFetcherSeller.get({
    url: apiSeller.productProperties(locale, productId),
  });
};

const getProperty = (locale: string, propertyId: any): Promise<IApiSellerProperty> => {
  return crudFetcherSeller.get({
    url: apiSeller.property(locale, propertyId),
  });
};

const getCategoryProperties = (locale: string, categoryId: any) => {
  return crudFetcherSeller.get({
    url: apiSeller.categoryProperties(locale, categoryId),
  });
};

const getSystemCategoryProperties = (locale: string, categoryId: any) => {
  return crudFetcherSeller.get({
    url: `${apiSeller.categoryProperties(locale, categoryId)}&is_custom=0`,
  });
};

interface addCustomPropertyDTO {
  name: string;
  description: string;
  ordering: number;
  group?: number | null;
  view_type?: number;
  category?: number;
}

const addCustomProperty = (requestData: addCustomPropertyDTO) => {
  return crudFetcherSeller.post({
    url: apiSeller.createProperty(),
    requestData: { ...requestData, is_custom: true },
  });
};

const updateCustomProperty = (propertyId: number, requestData: Partial<addCustomPropertyDTO>) => {
  return crudFetcherSeller.patch({
    url: apiSeller.updateProperty(propertyId),
    requestData: { ...requestData, is_custom: true },
  });
};

interface UpdateCustomPropertyDTO {
  title: string;
  description: string;
  ordering: number;
  measurable_value?: string | null;
  is_active?: boolean;
}

const updateCustomPropertyValue = (propertyValueId: number, requestData: UpdateCustomPropertyDTO) => {
  return crudFetcherSeller.put({
    url: apiSeller.updateCustomPropertyValue(propertyValueId),
    requestData: { ...requestData, is_custom: true },
  });
};

const checkCustomPropertyName = (name: string) => {
  return crudFetcherSeller.get({
    url: `${apiSeller.checkCustomPropertyName()}?property_name=${name}`,
  });
};

interface CustomPropertyValue {
  title: string;
  description: string;
  ordering: number;
  measurable_value?: string | null;
}

const addAdditionalPropertyValues = (propertyId: any, requestData: CustomPropertyValue[]) => {
  return crudFetcherSeller.post({
    url: apiSeller.addAdditionalPropertyValues(propertyId),
    requestData,
  });
};

// Properties
const batchCreateProperties = (
  requestData: {
    product_id: number;
    property_id: number;
    property_value_id: number;
  }[]
) => {
  return crudFetcherSeller.post({
    url: apiSeller.productPropertyBatchCreate(),
    requestData,
  });
};

const addProductProperty = (productId: any, requestData: any) => {
  return crudFetcherSeller.post({
    url: apiSeller.createProductProperties(productId),
    requestData,
  });
};

const updateProductProperty = (productId: any, propertyId: any, requestData: any) => {
  return crudFetcherSeller.put({
    url: apiSeller.updateProductProperty(productId, propertyId),
    requestData,
  });
};

const partUpdateProductProperty = (productId: any, propertyId: any, requestData: any) => {
  return crudFetcherSeller.patch({
    url: apiSeller.updateProductProperty(productId, propertyId),
    requestData,
  });
};

const deleteProductProperty = (productId: any, propertyId: any) => {
  return crudFetcherSeller.delete({
    url: apiSeller.updateProductProperty(productId, propertyId),
  });
};

// Intents
const batchCreateIntents = (
  requestData: {
    product_id: number;
    property_id: number;
    property_value_id: number;
  }[]
) => {
  return crudFetcherSeller.post({
    url: apiSeller.productIntentsBatchCreate(),
    requestData,
  });
};

interface CreateProductIntentDTO {
  ordering: number;
  product_id: number;
  property_id: number;
  property_value_id: number;
}

interface UpdateProductIntentDTO {
  ordering: number;
  product: number;
  property: number;
  property_value: number;
}

const addProductIntent = (requestData: CreateProductIntentDTO) => {
  return crudFetcherSeller.post({
    url: apiSeller.productAddIntent(),
    requestData,
  });
};

const updateProductIntent = (intentId: any, requestData: UpdateProductIntentDTO) => {
  return crudFetcherSeller.put({
    url: apiSeller.productIntent(intentId),
    requestData,
  });
};

const partUpdateProductIntent = (intentId: any, requestData: Partial<UpdateProductIntentDTO>) => {
  return crudFetcherSeller.patch({
    url: apiSeller.productIntent(intentId),
    requestData,
  });
};

const deleteProductIntent = (intentId: number) => {
  return crudFetcherSeller.delete({
    url: apiSeller.productIntent(intentId),
  });
};

// Variant

const getVariants = () => {
  return crudFetcherSeller.get({
    url: apiSeller.variants(),
  });
};

const deleteProductVariants = (productId: any) => {
  return crudFetcherSeller.delete({
    url: apiSeller.productDeleteVariants(productId),
  });
};

const getProductVariants = (productId: any) => {
  return crudFetcherSeller.get({
    url: apiSeller.productVariants(productId),
  });
};

const addVariant = (data: { product: any }) => {
  return crudFetcherSeller.post({
    url: apiSeller.variants(),
    requestData: { additional_pic: [], is_active: false, ...data },
  });
};

const getVariant = (variantId: any) => {
  return crudFetcherSeller.get({
    url: apiSeller.variant(variantId),
  });
};

interface updateVariantDTO {
  product: number;
  description?: string;
  sku?: string;
  price?: string;
  main_pic?: number | null;
  additional_pic: number[];
  in_stock?: boolean;
  is_active?: boolean;
}

const updateVariant = (variantId: any, requestData: updateVariantDTO) => {
  return crudFetcherSeller.put({
    url: apiSeller.variant(variantId),
    requestData,
  });
};

const partUpdateVariant = (variantId: any, requestData: Partial<updateVariantDTO>) => {
  return crudFetcherSeller.patch({
    url: apiSeller.variant(variantId),
    requestData,
  });
};

const deleteVariant = (variantId: any) => {
  return crudFetcherSeller.delete({
    url: apiSeller.variant(variantId),
  });
};

// Variant properties

const getVariantProperties = (variantId: any) => {
  return crudFetcherSeller.get({
    url: apiSeller.variantProperties(variantId),
  });
};

interface CreateVariantProperty {}

const addVariantProperty = (variantId: any, requestData: any) => {
  return crudFetcherSeller.post({
    url: apiSeller.variantProperties(variantId),
    requestData,
  });
};

const getVariantProperty = (variantId: any, propertyId: any) => {
  return crudFetcherSeller.get({
    url: apiSeller.variantProperty(variantId, propertyId),
  });
};

interface updateVariantPropertyDTO {
  ordering: number;
  property: number;
  property_value: number;
  variant: number;
}

const updateVariantProperty = (variantId: any, variantPropertyId: any, requestData: updateVariantPropertyDTO) => {
  return crudFetcherSeller.put({
    url: apiSeller.variantProperty(variantId, variantPropertyId),
    requestData,
  });
};

const partUpdateVariantProperty = (
  variantId: any,
  variantPropertyId: any,
  requestData: Partial<updateVariantPropertyDTO>
) => {
  return crudFetcherSeller.patch({
    url: apiSeller.variantProperty(variantId, variantPropertyId),
    requestData,
  });
};

const deleteVariantProperty = (variantId: any, variantPropertyId: any) => {
  return crudFetcherSeller.delete({
    url: apiSeller.variantProperty(variantId, variantPropertyId),
  });
};

export interface variantsBatchCreatePropertyDTO {
  variant_id: number;
  property_id: number;
  property_value_id: number;
  ordering?: number;
}

const variantsBatchCreateProperties = (requestData: variantsBatchCreatePropertyDTO[]) => {
  return crudFetcherSeller.post({
    url: apiSeller.variantsBatchCreateProperties(),
    requestData,
  });
};

// Addresses

const addAddress = (requestData: any) => {
  return crudFetcherSeller.post({
    url: apiSeller.addresses(),
    requestData,
  });
};

const updateAddress = (productId: any, requestData: any) => {
  return crudFetcherSeller.put({
    url: apiSeller.address(productId),
    requestData,
  });
};

const deleteAddress = (productId: any) => {
  return crudFetcherSeller.delete({
    url: apiSeller.address(productId),
  });
};

const getOrdersIdsByFilter = (filterStr: string): Promise<{ id: number }[]> => {
  return crudFetcherSeller.get({
    url: `${apiSeller.ordersIds()}${filterStr ? `?${filterStr}` : ""}`,
  });
};

const updateOrderStatus = (id: number, requestData: any) => {
  return crudFetcherSeller.patch({
    url: apiSeller.order(id),
    requestData,
    isSensitiveContentLanguage: true,
  });
};

const createOrderWaybill = (requestData: { provider: number; number: string; order: number }) => {
  return crudFetcherSeller.post({
    url: apiSeller.deliveryWaybills(),
    requestData,
    isSensitiveContentLanguage: true,
  });
};

const updateOrderWaybill = (waaybillId: any, requestData: { number: string }) => {
  return crudFetcherSeller.patch({
    url: apiSeller.deliveryWaybill(waaybillId),
    requestData,
  });
};

const cancelOrder = (orderId: any, requestData: { cancel_reason: string }) => {
  return crudFetcherSeller.post({
    url: apiSeller.orderCancel(orderId),
    requestData,
  });
};

const TEMPupdateAddress = (requestData: any) => {
  return crudFetcherSeller.put({
    url: apiSeller.addresses(),
    requestData,
  });
};

const approveOrder = (orderId: any) => {
  return crudFetcherSeller.post({
    url: apiSeller.orderApprove(orderId),
  });
}

// Delivery

const deliveries = () => {
  return crudFetcherSeller.get({
    url: apiSeller.deliveries(),
    isSensitiveContentLanguage: true,
  });
};

const addDelivery = (requestData: any) => {
  return crudFetcherSeller.post({
    url: apiSeller.deliveries(),
    requestData,
    isSensitiveContentLanguage: true,
  });
};

const updateDelivery = (deliveryId: any, requestData: any) => {
  return crudFetcherSeller.patch({
    url: apiSeller.delivery(deliveryId),
    requestData,
    isSensitiveContentLanguage: true,
  });
};

const deleteDelivery = (deliveryId: any) => {
  return crudFetcherSeller.delete({
    url: apiSeller.delivery(deliveryId),
  });
};

const addBrand = (requestData: any) => {
  return crudFetcherSeller.post({
    url: apiSeller.brands(),
    requestData,
  });
};

const getFileByUrl = (url: string) => {
  return crudFetcherSeller.get({
    url,
    options: {
      responseType: "blob",
    },
  });
};

const downloadFileByUrl = (url: string, filename: string) =>
  getFileByUrl(url).then((data) => downloadFile(data, filename));

// const downloadProductsTemplate = (content: "csv" | "xlsx" | "xml") => {
//   return downloadFileByUrl(apiSeller.templateFileUploads(content, "products"), `products_template.${content}`);
// };

const downloadProductsTemplateCSV = () => {
  return downloadFileByUrl(apiSeller.csvTemplate(), `products_template.csv`);
}

const downloadProductsTemplateXLSX = () => {
  return downloadFileByUrl(`${apiSeller.csvTemplate()}?content=xlsx`, `products_template.xlsx`);
}

const downloadProductsTemplateXML = () => {
  return downloadFileByUrl(apiSeller.xmlTemplate(), `products_template.xml`);
}

const addCategoryToFavorite = (id: number) => {
  return crudFetcherSeller.post({
    url: apiSeller.addCategoryToCategory(),
    requestData: { category: id },
  });
};

const deleteCategoryFromFavorite = (id: number) => {
  return crudFetcherSeller.delete({
    url: apiSeller.deleteCategoryFromFavorite(id),
  });
};

const csvUpload = (file: File) => {
  const formdata = new FormData();

  if (file?.name !== "undefined") {
    formdata.append("csv_file", file);
  } else {
    formdata.append("csv_file", new File([file], `avatar-${Date.now()}.jpeg`));
  }

  return crudFetcherSeller.post({
    url: apiSeller.csvUpload(),
    requestData: formdata,
  });
};

const getProductsTotalAmount = () => {
  return crudFetcherSeller.get({
    url: apiSeller.productsAmount(),
  });
};

//REVIEWS

const exportProductsReviewCSV = (filterStr?: string) => {
  return crudFetcherSeller.get({
    url: `${apiSeller.ordersFeedbackCSV()}?${filterStr}`,
  });
};

// PROFILE

const getSellerProfile = (): Promise<IApiSellerProfile | null> => {
  return crudFetcherSeller.get({
    url: apiSeller.profile(),
  });
};

const updateSellerProfile = (requestData: any) => {
  return crudFetcherSeller.patch({
    url: apiSeller.profile(),
    requestData,
    isSensitiveContentLanguage: true,
  });
};

// FILES
interface RequestExport {
  name?: string;
  content_type: keyof typeof FileContentType;
  categories?: number[];
  items?: number[];
}

const requestExport = (requestData: RequestExport) => {
  return crudFetcherSeller.post({
    url: apiSeller.filesDownloadsRequestExport(),
    requestData,
  });
};

const requestImportCSV = (requestData: { content_type: string; file: File }) => {
  const { content_type, file } = requestData;
  const formData = new FormData();
  formData.append('file', new File([file], file.name));
  formData.append('content_type', content_type);

  return crudFetcherSeller.post({
    url: apiSeller.filesUploadsRequestImportCSV(),
    requestData: formData,
  });
};

const requestImportXML = (
  requestData: { content_type: string; file: File },
  onProgress?: (progress: string) => void
) => {
  const { file } = requestData;

  return new Promise((resolve, reject) => {
    const chunkSize = Math.ceil(1 * 1024 * 1024); // 1MB (adjust based on your requirements)
    uploadChunk(
      { crudFetcher: crudFetcherSeller.post, url: apiSeller.filesUploadsRequestImportXML(), file, chunkSize },
      resolve,
      reject,
      onProgress
    );
  });
};

// SUPPORT

const sendQuestionToSupport = (requestData: any) => {
  return crudFetcherSeller.post({
    url: apiSeller.support(),
    requestData,
  });
};

const sellerRequests = {
  getStore,
  getCategory,
  getCategoryById,
  getCategoriesByIds,
  getCategoriesByParent,
  updateStore,
  getProductsIds,
  addProduct,
  updateProduct,
  bulkUpdateProducts,
  deleteProduct,
  uniquenessCheckSKU,
  getProperties,
  getSystemProperties,
  getCustomProperties,
  getProductProperties,
  getProperty,
  getCategoryProperties,
  getSystemCategoryProperties,
  addCustomProperty,
  updateCustomProperty,
  updateCustomPropertyValue,
  checkCustomPropertyName,
  addAdditionalPropertyValues,
  batchCreateProperties,
  addProductProperty,
  updateProductProperty,
  partUpdateProductProperty,
  deleteProductProperty,
  batchCreateIntents,
  addProductIntent,
  updateProductIntent,
  partUpdateProductIntent,
  deleteProductIntent,
  getVariants,
  deleteProductVariants,
  getProductVariants,
  addVariant,
  getVariant,
  updateVariant,
  partUpdateVariant,
  deleteVariant,
  getVariantProperties,
  addVariantProperty,
  getVariantProperty,
  updateVariantProperty,
  partUpdateVariantProperty,
  deleteVariantProperty,
  variantsBatchCreateProperties,
  getFileByUrl,
  downloadFileByUrl,
  downloadProductsTemplateCSV,
  downloadProductsTemplateXLSX,
  downloadProductsTemplateXML,
  csvUpload,
  addAddress,
  updateAddress,
  deleteAddress,
  TEMPupdateAddress,
  deliveries,
  addDelivery,
  updateDelivery,
  getOrdersIdsByFilter,
  updateOrderStatus,
  cancelOrder,
  createOrderWaybill,
  updateOrderWaybill,
  deleteDelivery,
  addBrand,
  addCategoryToFavorite,
  deleteCategoryFromFavorite,
  getProductsTotalAmount,
  exportProductsReviewCSV,
  switchStoreActivation,
  getSellerProfile,
  updateSellerProfile,
  requestExport,
  requestImportCSV,
  requestImportXML,
  sendQuestionToSupport,
  approveOrder,
};

export default sellerRequests;
