export const uploadChunk = (
  data: {
    id?: number;
    crudFetcher: any;
    url: string;
    file: File;
    chunkSize: number;
    chunkNumber?: number;
    start?: number;
    end?: number;
  },
  resolve = (value: unknown) => {},
  reject = () => {},
  onProgress = (progress: string) => {}
) => {
  const { id, crudFetcher, url, chunkNumber = 0, chunkSize, start = 0, end = 0, file } = data;
  const totalChunks = Math.ceil(file.size / chunkSize);
  const chunkProgress = ((end / file.size) * 100).toFixed(1);
  console.log('uploadChunk', { size: file.size, chunkSize, totalChunks, start, end, chunkProgress });
  if (start >= file.size) {
    onProgress('100');
    resolve({ id });
    return;
  }
  onProgress(chunkProgress);

  const chunk = file.slice(start, start + chunkSize);
  console.log('CHUNK', chunk, chunk.size);
  const formData = new FormData();
  formData.append('file', new File([chunk], file.name));
  formData.append('chunkNumber', `${chunkNumber}`);
  formData.append('totalChunks', `${totalChunks}`);
  if (id) {
    formData.append('data', JSON.stringify({ id }));
  }

  return crudFetcher({
    url,
    requestData: formData,
  })
    .then((resp: any) => {
      console.log({ resp });
      uploadChunk(
        {
          id: id || resp.id,
          crudFetcher,
          url,
          file,
          chunkNumber: chunkNumber + 1,
          chunkSize,
          start: start + chunkSize,
          end: start + chunkSize + chunkSize,
        },
        resolve,
        reject,
        onProgress
      );
    })
    .catch(reject);
};
