export const MIME_TYPES = {
  TXT: "text/plain",
  XLSX: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  CSV: "text/csv",
  PNG: "image/png",
  JPEG: "image/jpeg",
  PDF: "application/pdf",
  DOC: "application/msword",
  DOCX: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  XLS: "application/vnd.ms-excel",
};

export const fileSize2Str = (size: number) => {
  if (size < 1024) {
    return size + "bytes";
  } else if (size > 1024 && size < 1048576) {
    return (size / 1024).toFixed(1) + "KB";
  } else if (size > 1048576) {
    return (size / 1048576).toFixed(1) + "MB";
  }
};

export const downloadFile = (data: any, filename: string, type?: string) => {
  const ext = filename.split(".").slice(-1)[0];
  // @ts-ignore
  const blobContentType = type || MIME_TYPES[`${ext}`.toUpperCase()] || MIME_TYPES.TXT;
  const file = new Blob([data], { type: blobContentType });

  const a = document.createElement("a");
  const url = URL.createObjectURL(file);
  a.href = url;
  a.download = filename;

  document.body.appendChild(a);
  a.click();

  setTimeout(function () {
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }, 0);
};
